<template>
  <!-- Header -->
  <div class="relative md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 pb-4">
            <card-stats
              statSubtitle="Всего детей в группе"
              statTitle="30 детей"
              statIconName="fas fa-child"
              statIconColor="bg-green-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 pb-4">
            <card-stats
              statSubtitle="Бюджет на учебный год"
              statTitle="50000 RUB"
              statIconName="fas fa-hand-holding-usd"
              statIconColor="bg-blue-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 pb-4">
            <card-stats
              statSubtitle="Собрано средств всего"
              statTitle="25000 RUB"
              statIconName="fas fa-wallet"
              statIconColor="bg-pink-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 pb-4">
            <card-stats
              statSubtitle="Не сдали средства"
              statTitle="5 представителей"
              statIconName="fas fa-thumbs-down"
              statIconColor="bg-red-600"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 pb-4">
            <card-stats
              statSubtitle="Расходы нарастающим итогом"
              statTitle="20000 RUB"
              statIconName="fas fa-money-bill-alt"
              statIconColor="bg-orange-500"
            />
          </div>
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4 pb-4">
            <card-stats
              statSubtitle="Остаток средств"
              statTitle="5000 RUB"
              statIconName="fas fa-piggy-bank"
              statIconColor="bg-yellow-500"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from '@/components/Cards/CardStats.vue'

export default {
  components: {
    CardStats
  }
}
</script>
