<template>
  <div>
    <header-stats/>
  </div>
</template>
<script>
import HeaderStats from '@/components/Headers/HeaderStats.vue'

export default {
  name: 'dashboard-page',
  components: {
    HeaderStats
  }
}
</script>
